import * as React from "react";
import { linkResolver } from "../../utilities/prismic-linkResolver";
import Anchor from "./Anchor";

const LanguageSwitcher = ({ activeDocMeta }) => {
  if (!activeDocMeta) return null;

  // Hide switcher when we don't have other languages
  if (activeDocMeta.alternateLanguages?.length <= 0) return null;

  const currentLang = activeDocMeta.lang;
  const currentLangOption = currentLang.slice(0, 2).toUpperCase();

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang) => (
      <Anchor
        className="ms-2 fw-bold"
        key={altLang.lang}
        href={linkResolver(altLang)}
      >
        {altLang.lang.slice(0, 2).toUpperCase().replace("EL", "GR")}
      </Anchor>
    )
  );

  return (
    <div className="my-2 ms-lg-5">
      <span className="fw-bold text-white">
        {currentLangOption.replace("EL", "GR")}
      </span>
      {alternateLangOptions}
    </div>
  );
};

export default LanguageSwitcher;
