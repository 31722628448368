/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 * @returns URL for the provided Prismic document.
 */

const defaultLanguage = require("../../config/site-config").default?.siteLang;

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.split("-")[0]}/`;
    }
    case "simple-page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "aboutpage": {
      return doc.lang === defaultLanguage
        ? `/about/`
        : `/${doc.lang.split("-")[0]}/about/`;
    }
    default: {
      return "/";
    }
  }
};
