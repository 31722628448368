import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import "../../styles/main.scss";

const Layout = ({ children, settings, activeDocMeta }) => {
  if (!settings) return null;
  const { code_for_head: codeHead, code_for_body: codeBody } = settings.data;

  useEffect(() => {
    if (codeHead.text !== "") {
      document.head.append(
        document.createRange().createContextualFragment(codeHead.text)
      );
    }
    if (codeBody.text !== "") {
      document.body.append(
        document.createRange().createContextualFragment(codeBody.text)
      );
    }
  }, [codeBody.text, codeHead.text]);

  return (
    <>
      <Helmet defaultTitle={settings.data.site_short_title}>
        <html lang={settings.lang} />
      </Helmet>
      <div className="site">
        <Header settings={settings} activeDocMeta={activeDocMeta} />
        <main className="site-main">{children}</main>
        <Footer settings={settings} />
      </div>
    </>
  );
};

export default Layout;
