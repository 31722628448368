import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Navbar } from "react-bootstrap";
import Anchor from "../elements/Anchor";
import LanguageSwitcher from "../elements/LanguageSwitcher";

const Header = ({ activeDocMeta, settings }) => {
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  const navItems = settings.data.body;

  return (
    <Navbar
      variant="dark"
      bg="dark py-4"
      expand="lg"
      className="fixed-top px-xl-4 px-xxl-5"
    >
      <Container fluid>
        <Anchor href={navItems[0].primary.link.url} className="me-4">
          <StaticImage src="../../images/logo.png" alt="logo" />
        </Anchor>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div
            className="d-flex flex-column flex-lg-row align-items-start mt-3 mt-lg-0"
            style={{ width: "100%" }}
          >
            {navItems.map((navItem) => {
              return (
                <Anchor
                  href={navItem.primary.link.url}
                  className={`fw-bold my-2 ms-lg-5 ${path === navItem.primary.link.url
                    ? "text-primay"
                    : "text-white"
                    }`}
                  key={navItem.id}
                >
                  {navItem.primary.label}
                </Anchor>
              );
            })}
            <Anchor
              href="tel:+306974828240"
              className="fw-bold my-2 ms-lg-auto"
            >
              <span>TEL: </span>
              <span className="text-white">+306974828240</span>
            </Anchor>
            <LanguageSwitcher activeDocMeta={activeDocMeta} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
