import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";

const Footer = ({ settings }) => {
  // console.log(settings);

  return (
    <footer className="bg-dark text-white p-2 pt-5 p-sm-4">
      <Container
        fluid
        className="border border-primary border-4 px-2 px-sm-4 px-md-5 py-5 d-flex justify-content-between align-items-center"
      >
        <div>
          <StaticImage src="../../images/sima-gold.png" alt="footer logo" />
        </div>


        <div className="text-primary ps-4 ps-sm-0">
          <div className="d-flex flex-column mb-4">
            <span>&copy; GEMILY | 2022</span>
            <span>All Rights Reserved</span>
          </div>
          <a href="https://think-plus.gr/" className="d-flex flex-column">
            <span>Web Design & Development </span>
            <span>
              by<strong> Τhink Plus</strong>
            </span>
          </a>
        </div>
      </Container>
    </footer>
  )
};

export default Footer;
